import useIsMobile from 'hooks/useIsMobile';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { TripletPartChart } from './TripletPartChart';
import { ChartType, TripletSeries } from 'shared/types/analytics';
import { ApexOptions } from 'apexcharts';

const tripletStyle = (height: string | number): object => ({
  width: '100%',
  height: height,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '3fr 1fr',
});
const wrapperStyle = {
  display: 'flex',
  flexDirection: 'row' as 'row',
  height: '100%',
};

export function TripletChart({
  series,
  options,
}: {
  series: TripletSeries;
  options: ApexOptions;
}): ReactElement {
  const isMobile = useIsMobile();
  const intl = useIntl();
  return (
    <div style={wrapperStyle}>
      <div id='chart' style={tripletStyle(350)}>
        <div
          style={{
            fontSize: '42px',
            display: 'inline-block',
            alignSelf: 'center',
            justifySelf: 'center',
          }}
        >
          {series[0].value}
          {options.labels?.[0]}
        </div>
        <div
          style={{
            fontSize: '42px',
            display: 'inline-block',
            alignSelf: 'center',
            justifySelf: 'center',
          }}
        >
          <TripletPartChart
            color='#FDB22B'
            chartType={ChartType.radialBar}
            index={1}
            isMobile={isMobile}
            options={options}
            series={series}
          />
        </div>
        <div
          style={{
            fontSize: '42px',
            display: 'inline-block',
            alignSelf: 'center',
            justifySelf: 'center',
          }}
        >
          <TripletPartChart
            color='#288AE2'
            chartType={ChartType.radialBar}
            index={2}
            isMobile={isMobile}
            options={options}
            series={series}
          />
        </div>
        <div
          style={{
            fontSize: '16px',
            display: 'inline-block',
            justifySelf: 'center',
          }}
        >
          {intl.formatMessage({ id: 'completed_inspections_chart' })}
        </div>
        <div
          style={{
            fontSize: '16px',
            display: 'inline-block',
            justifySelf: 'center',
          }}
        >
          {intl.formatMessage({ id: 'failed_inspection_factor_chart' })}
        </div>
        <div
          style={{
            fontSize: '16px',
            display: 'inline-block',
            justifySelf: 'center',
          }}
        >
          {intl.formatMessage({ id: 'average_inspection_result_chart' })}
        </div>
      </div>
    </div>
  );
}
