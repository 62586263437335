import { InspectionTripletFilters } from 'shared/types/analytics';
import { setMultiselectFilterValue } from 'charts/shared/filters';
import { FilterAction } from '../types';

export function filtersReducer(
  state: InspectionTripletFilters | undefined,
  action: FilterAction
): InspectionTripletFilters | undefined {
  if (action.type === 'REWRITE_FILTERS') return action.payload;
  if (!state) return undefined;

  switch (action.type) {
    case 'SET_DATE_RANGE':
      const [first, ...rest] = state.dataScope.filters;
      return {
        ...state,
        dataScope: {
          ...state.dataScope,
          filters: [{ ...first, value: action.payload }, ...rest],
        },
      };

    case 'SET_PROCESSES':
      return setMultiselectFilterValue(state, 1, action.payload);

    case 'SET_SITES':
      return setMultiselectFilterValue(state, 2, action.payload);

    case 'SET_MANAGERS':
      return setMultiselectFilterValue(state, 3, action.payload);
    default:
      const exhaustiveCheck: never = action;
      throw new Error(
        `Unhandled filters reducer case: ${exhaustiveCheck['type']}`
      );
  }
}
