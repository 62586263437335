import { Dispatch, useCallback, useMemo } from 'react';
import { SiteModel } from 'shared/domain/site/types/model';
import { FilterValues } from 'shared/types/analytics';
import { LabelledEntity } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import {
  DependencyActionCalls,
  InitializeDependencyAction,
} from '../types';
import { DependeciesActionEnum } from 'charts/shared/types';

export function useDependenciesActions(
  dispatch: Dispatch<InitializeDependencyAction>
): DependencyActionCalls {
  const setFiltersResponse = useCallback(
    (value: FilterValues) => {
      dispatch({
        type: DependeciesActionEnum.setFiltersResponse,
        payload: value,
      });
    },
    [dispatch]
  );

  const setProcesses = useCallback(
    (value: ProcessType[]) => {
      dispatch({
        type: DependeciesActionEnum.setProcesses,
        payload: value,
      });
    },
    [dispatch]
  );

  const setSites = useCallback(
    (value: SiteModel[]) => {
      dispatch({ type: DependeciesActionEnum.setSites, payload: value });
    },
    [dispatch]
  );

  const setProjectId = useCallback(
    (value: string) => {
      dispatch({
        type: DependeciesActionEnum.setProjectId,
        payload: value,
      });
    },
    [dispatch]
  );
  const setTimezone = useCallback(
    (value: string) => {
      dispatch({
        type: DependeciesActionEnum.setTimezone,
        payload: value,
      });
    },
    [dispatch]
  );
  const setManagers = useCallback(
    (value: LabelledEntity[]) => {
      dispatch({
        type: DependeciesActionEnum.setManagers,
        payload: value,
      });
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      setManagers,
      setTimezone,
      setProcesses,
      setProjectId,
      setFiltersResponse,
      setSites,
    }),
    []
  );
}
