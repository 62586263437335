import { DependeciesActionEnum, Dependencies } from 'charts/shared/types';
import {
  InspectionTripletDependencies,
  InitializeDependencyAction,
} from '../types';
import { InspectionTripletFilters } from 'shared/types/analytics';

export function dependenciesReducer(
  state: Dependencies<
    InspectionTripletDependencies,
    InspectionTripletFilters
  >,
  action: InitializeDependencyAction
): Dependencies<InspectionTripletDependencies, InspectionTripletFilters> {
  switch (action.type) {
    case DependeciesActionEnum.setFiltersResponse:
      return {
        ...state,
        filters: action.payload,
      };

    case DependeciesActionEnum.setProcesses:
      return {
        ...state,
        processes: action.payload,
      };

    case DependeciesActionEnum.setSites:
      return {
        ...state,
        sites: action.payload,
      };

    case DependeciesActionEnum.setManagers:
      return {
        ...state,
        managers: action.payload,
      };

    case DependeciesActionEnum.setProjectId:
      return {
        ...state,
        projectId: action.payload,
      };

    case DependeciesActionEnum.setTimezone:
      return {
        ...state,
        timezone: action.payload,
      };
    default:
      const exhaustiveCheck: never = action;
      throw new Error(
        `Unhandled dependencies reducer case: ${exhaustiveCheck['type']}`
      );
  }
}
