import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import {
  InspectionTripletFilters,
  TripletSeriesWithLabels,
} from 'shared/types/analytics';
import {
  filterByCreatedBy,
  filterByDateRange,
  filterByProcess,
  filterInspectionBySite,
} from '../seriesCreation';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';

export async function createSeriesAndLabelsFromInspectionsWithFilters(
  inspections: InspectionEntity[],
  templateProcessByIdRecord: Record<string, { process: string }>,
  filters: InspectionTripletFilters,
  timezone: string
): Promise<TripletSeriesWithLabels> {
  const [dateRangeFilter, processFilter, siteFilter, createdByFilter] =
    filters.dataScope.filters;

  const protocolResultsObject = {
    [ComplianceCheckResult.passed]: 0,
    [ComplianceCheckResult.notPassed]: 0,
    [ComplianceCheckResult.notApplicable]: 0,
  };

  const filteredInspections = inspections.filter((inspection) => {
    const passedDateRange = filterByDateRange(
      dateRangeFilter.value,
      inspection.inspectionDate || inspection.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return false;
    }

    if (!templateProcessByIdRecord[inspection.template]) {
      throw new Error(
        `Template _id:${inspection.template}; missing for inspection _id:${inspection._id}`
      );
    }
    const passedProcess = filterByProcess(
      processFilter.value,
      templateProcessByIdRecord[inspection.template]
    );
    if (!passedProcess) {
      return false;
    }

    const passedSite = filterInspectionBySite(
      siteFilter.value,
      inspection
    );
    if (!passedSite) {
      return false;
    }

    const passedInspector = filterByCreatedBy(
      createdByFilter.value,
      inspection
    );
    if (!passedInspector) {
      return false;
    }

    if (!inspection.isCompleted) {
      return false;
    }
    inspection.protocol.forEach((protocolElement) => {
      if (
        protocolElement.complianceCheck.result ===
        ComplianceCheckResult.unset
      ) {
        return;
      }
      protocolResultsObject[protocolElement.complianceCheck.result] += 1;
    });
    return true;
  });

  const passed = protocolResultsObject[ComplianceCheckResult.passed];
  const notPassed = protocolResultsObject[ComplianceCheckResult.notPassed];

  const failedRatio = normalize(
    (notPassed / (passed + notPassed || 1)) * 100
  );
  const inspectionScore = normalize(
    (passed / (passed + notPassed || 1)) * 100
  );

  return [
    [
      { value: filteredInspections.length },
      { value: failedRatio, maxValue: 100 },
      { value: inspectionScore, maxValue: 100 },
    ],
    ['', '%', '%'],
  ];
}

function normalize(value: number): number {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
